import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";
import Close from "@mui/icons-material/Close";

import type { Maybe } from "src/utils/types";

interface CompanyLabelProps {
  companyId: Maybe<string>;
  companyName: Maybe<string>;
  source: string;
}

export const CompanyLabel = ({ companyId, companyName, source }: CompanyLabelProps) => {
  if (companyName) {
    return (
      <Link
        href={{ pathname: "/companies/[id]", query: { id: companyId, source: source } }}
        passHref
        legacyBehavior
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" fontWeight="500" color="#0B4ED5">
              {companyName}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    );
  }
  return <Close sx={{ fontSize: 14 }} color="error" />;
};
