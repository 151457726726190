import Paper from "@mui/material/Paper";
import Head from "next/head";
import React, { type ChangeEvent, useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import type { OrderingField } from "src/components/job/JobListFilter/useJobFilters";
import {
  useJobFilterDispatch,
  useJobFilterStore,
} from "src/components/job/JobListFilter/useJobFilters";
import {
  useFailedToAutoAllocateQFCount,
  useJobsheetUncompletedQFCount,
  useNoAccessQFCount,
  useQuoteResponsePendingQFCount,
  useQuotesSentToOpsQFCount,
  useRequiresJobsheetApprovalQFCount,
} from "src/components/job/JobListFilter/useQuickFilterCounts";
import { JobNoteModal } from "src/components/job/JobNoteModal";
import { useAuthorisationGuard } from "src/hooks/useAuthorisationGuard";
import { useJobsQuery } from "src/hooks/useJobsQuery";
import { Layout } from "src/layout/Layout";
import { useAddOrRemovePeopleFromJob, usePinJob, useUploadBulkJobs } from "src/services/mutations";

import { JobListResults } from "../../components/job/JobList/JobListResults";
import { JobListToolbar } from "../../components/job/JobList/JobListToolbar";
import { JobTagModal } from "src/components/job/JobTagModal";
import type { AlertColor } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useAuthStore } from "../../context/authStore";

const Jobs = () => {
  useAuthorisationGuard("jobs");
  const isAdmin = useAuthStore((state) => state.isAdmin);

  const filterDispatch = useJobFilterDispatch();

  const [jobNoteId, setJobNoteId] = useState<{ jobId: string; partnerId: string } | null>(null);
  const [jobTagId, setJobTagId] = useState<{
    jobId: string;
    companyId: string | undefined;
  } | null>(null);

  // quick filter queries
  const failedToAutoAllocateQFCount = useFailedToAutoAllocateQFCount();
  const noAccessQFCount = useNoAccessQFCount();
  const quotesSentToOpsQFCount = useQuotesSentToOpsQFCount();
  const quoteResponsePendingQFCount = useQuoteResponsePendingQFCount();
  const jobsheetUncompletedQFCount = useJobsheetUncompletedQFCount();
  const requiresJobsheetApprovalQFCount = useRequiresJobsheetApprovalQFCount();

  const page = useJobFilterStore((state) => state.page);
  const limit = useJobFilterStore((state) => state.limit);

  const quickFilterStats = {
    failedToAutoAllocateQFCount,
    noAccessQFCount,
    quotesSentToOpsQFCount,
    quoteResponsePendingQFCount,
    jobsheetUncompletedQFCount,
    requiresJobsheetApprovalQFCount,
  };

  const jobsQuery = useJobsQuery();

  const pinJob = usePinJob();
  const addOrRemovePeopleFromJob = useAddOrRemovePeopleFromJob();
  const queryClient = useQueryClient();

  const handleToggleJobPin = useCallback(
    (jobId: string, pin: boolean) => {
      pinJob.mutate(
        { id: jobId, input: { pin: pin } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(jobsQuery.queryKey);
          },
        },
      );
    },
    [jobsQuery.queryKey, pinJob, queryClient],
  );

  const addOrRemovePeople = useCallback(
    (jobRefId: string, eventType: "add" | "remove", initials: string) =>
      addOrRemovePeopleFromJob.mutateAsync(
        {
          jobRefId,
          payload: {
            eventType,
            initials,
          },
        },
        {
          onSuccess: (people) => {
            queryClient.setQueryData(jobsQuery.queryKey, {
              ...jobsQuery.data,
              results: jobsQuery.data?.results.map((job) => {
                if (job.referenceId === jobRefId) {
                  return { ...job, people: people };
                }
                return job;
              }),
            });
          },
        },
      ),
    [addOrRemovePeopleFromJob, jobsQuery.data, jobsQuery.queryKey, queryClient],
  );

  const jobs = jobsQuery.data?.results?.filter((job) => job.referenceId) ?? [];
  const count = jobsQuery.data?.count ?? 0;

  const [snackBarContent, setSnackBarContent] = useState<null | { color: AlertColor; msg: string }>(
    null,
  );

  const uploadBulkJobs = useUploadBulkJobs({
    onSuccess: () => {
      setSnackBarContent({
        color: "success",
        msg: "Bulk import file uploaded successfully! check #ovox-ops-commands for details.",
      });
    },
    onError: () => {
      setSnackBarContent({
        color: "error",
        msg: "Bulk import file upload failed",
      });
    },
  });

  const handleUploadBulkJobs = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log("trying upload");
    if (file) {
      uploadBulkJobs.mutateAsync({ file });
    }
  };

  return (
    <>
      <Head>
        <title>Jobs</title>
      </Head>

      <Paper elevation={1}>
        <JobListToolbar
          {...quickFilterStats}
          initiateFileUpload={handleUploadBulkJobs}
          isUploading={uploadBulkJobs.isLoading}
          isAdmin={isAdmin}
        />
        <JobListResults
          page={page}
          count={count}
          limit={limit}
          jobs={jobs}
          onOpenNotes={(jobId, partnerId) => setJobNoteId({ jobId, partnerId })}
          onOpenTags={(jobId, companyId) =>
            setJobTagId({ jobId, companyId: companyId ?? undefined })
          }
          onPageChange={(_, page) => filterDispatch({ kind: "changePage", page })}
          onLimitChange={(e) => {
            filterDispatch({ kind: "changeLimit", limit: parseInt(e.target.value, 10) });
          }}
          onSortOptions={(col) => {
            filterDispatch({ kind: "toggleFieldOrder", field: col as OrderingField });
          }}
          onTogglePin={handleToggleJobPin}
          addOrRemovePeople={addOrRemovePeople}
        />
      </Paper>

      {jobNoteId ? (
        <JobNoteModal
          showRecentNotes
          isOpen
          jobId={jobNoteId.jobId}
          partnerId={jobNoteId.partnerId}
          onClose={() => setJobNoteId(null)}
        />
      ) : null}
      {jobTagId ? (
        <JobTagModal
          jobId={jobTagId.jobId}
          companyId={jobTagId.companyId ?? undefined}
          isOpen
          onClose={() => setJobTagId(null)}
        />
      ) : null}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={!!snackBarContent}
        autoHideDuration={5000}
        onClose={() => setSnackBarContent(null)}
      >
        <Alert onClose={() => setSnackBarContent(null)} severity={snackBarContent?.color}>
          {snackBarContent?.msg}
        </Alert>
      </Snackbar>
    </>
  );
};

Jobs.getLayout = (page: React.ReactNode) => <Layout>{page}</Layout>;

export default Jobs;
