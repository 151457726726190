import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React from "react";
import { useIsCompaniesUIEnabled } from "src/hooks/flags/useCompaniesEnabled";

import { useTradespersonPartner } from "src/services/queries";
import { getShortReferenceId } from "src/utils/jobs";
import type { Maybe } from "src/utils/types";

interface TradespersonLabelProps {
  referenceId: Maybe<string>;
  companyId?: string;
}

export const TradepsersonLabel = ({ referenceId, companyId }: TradespersonLabelProps) => {
  const tradespersonQuery = useTradespersonPartner({
    variables: { id: referenceId as string },
    skip: !referenceId,
  });
  const tradesperson = tradespersonQuery.data;
  const companiesEnabled = useIsCompaniesUIEnabled();
  const redirectToCompany = companyId && companiesEnabled;

  if (tradesperson?.tradesperson) {
    return (
      <Link
        href={{
          pathname: redirectToCompany ? "/companies/[id]" : "/tradespeople/[id]",
          query: {
            id: redirectToCompany ? companyId : tradesperson.referenceId,
            ...(redirectToCompany && { tab: "tradespeople" }),
            ...(redirectToCompany && { source: "jobs" }),
          },
        }}
        passHref
        legacyBehavior
      >
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body2" fontWeight="500" color="#0B4ED5">
              {tradesperson.tradesperson.companyName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="#478EFF">
              {tradesperson.tradesperson.fullName}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    );
  } else if (referenceId) {
    return <>{getShortReferenceId(referenceId)}</>;
  } else {
    return <>Unallocated</>;
  }
};
